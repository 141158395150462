<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';


import {nextTick, onMounted} from 'vue'
import {PropertyStrategyThemeType} from '@toolify/client/src/modules/tableItems/enum/PropertyStrategyThemeType'
import {useKeyboardShortcuts} from '@toolify/client/src/modules/keyboard/composables/useKeyboardShortcuts'

interface Props {
  type?: 'text'|'number'|'password'|'datetime-local'
  focusOnMounted?: boolean
  selectOnMounted?: boolean
  modelValue?: string|number
  background?: 'lowest'|'low'|'normal'|'high'
  theme?: PropertyStrategyThemeType
  placeholder?: string
  showOnClick?: boolean
  textAlignment?: 'left'|'center'|'right'
  isEditing?: boolean
  fullWidth?: boolean
  iconClass?: string
  showClearIcon?: boolean
}

interface Emits {
  (e: 'update:modelValue', value: string|number)
  (e: 'update:isEditing', value: boolean)
  (e: 'change', value: string|number)
  (e: 'blur', value: string|number)
  (e: 'enter')
  (e: 'tab')
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  focusOnMounted: false,
  selectOnMounted: false,
  modelValue: '',
  theme: PropertyStrategyThemeType.Primary,
  placeholder: '',
  showOnClick: false,
  background: null,
  textAlignment: 'left',
  fullWidth: false,
  iconClass: '',
  showClearIcon: false,
})

const emit = defineEmits<Emits>()

const onInput = (event) => {
  let targetValue: string|number
  if(props.type === 'number') {
    targetValue = Number(event.target.value)
  } else {
    targetValue = String(event.target.value)
  }
  emit('update:modelValue', targetValue)
}

const input = _ref(null)

const value = _computed({
  get() {
    if(props.type === 'number') {
      if(!props.modelValue) {
        return ''
      }
      return Number(props.modelValue)
    }
    if(!props.modelValue) {
      return ''
    }
    return String(props.modelValue)
  },
  set(value) {
    if(props.type === 'number') {
      return emit('change', Number(value))
    }
    emit('change', String(value))
  },
})

const keyboardShortcuts = useKeyboardShortcuts([])
onMounted(async () => {
  keyboardShortcuts.unregisterShortcuts()
  if(props.focusOnMounted) {
    await nextTick(() => input.value?.focus())
  }
  if(props.selectOnMounted) {
    await nextTick(() =>{
      input.value?.focus()
      input.value?.select()
      
    })
  }
})

const focus = () => {
  input.value?.focus()
}

const isEditing = _computed({
  get() {
    return props.isEditing
  },
  set(value) {
    emit('update:isEditing', value)
  },
})

defineExpose({
  focus,
})

const additionalStyles = _computed(() => {
  return {
    'text-align': props.textAlignment,
    'width': props.fullWidth ? '100%' : undefined,
  }
})

</script>

<template>
  <input
    :class="['input', `theme-${props.theme}`, `background-${props.background}`]"
    ref="input"
    :type="props.type"
    :placeholder="props.placeholder"
    @input="onInput"
    :value="value"
    v-bind="$attrs"
    @focus="keyboardShortcuts.registerShortcuts(); isEditing = true"
    @focusout="keyboardShortcuts.unregisterShortcuts()"
    @change="emit('change', ($event.target as HTMLInputElement).value)"
    :style="additionalStyles"
    @blur="emit('blur', ($event.target as HTMLInputElement).value) ; isEditing = false"
  />
</template>

<style scoped lang="scss">
  @use '../../../../styles/mixins' as mixins;
  @use '../../../../styles/variables' as variables;

  .input {
    @include mixins.inputStyle;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
    color: map-get(variables.$colors, content-normal) !important;
    -webkit-text-fill-color: map-get(variables.$colors, content-normal) !important;
    caret-color: map-get(variables.$colors, content-normal) !important;
  }
  /// TODO move This to proper place
  .theme-checklist {
    height: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-radius: 4px;
    background-color: map-get(variables.$colors, container-low);
    color:map-get(variables.$colors, content-dark);
    padding: 4px;
    &::placeholder {
      color: map-get(variables.$colors, content-dark);
    }
    &:hover{
      &::placeholder {
        color: map-get(variables.$colors, content-dark);
      }
    }
  }
</style>
